<template>
  <Container
    :preloader="preloader"
    class="chart"
  >
    <template #content>
      <div class="chart__start">
        {{ $t('views.default.payouts.charts.total.total-paid') }}
      </div>

      <div class="chart__center">
        ${{ $format.wallet(value.last_week_income) }}
      </div>

      <div class="chart__end">
        {{ $t('views.default.payouts.charts.total.over-yield-period') }}

        <span>
          {{ value.period?.start }} - {{ value.period?.end }}
        </span>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'

export default {
  components: {
    Container
  },
  data () {
    return {
      preloader: true,
      value: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get('private/widgets/week-total')

        if (status === 200) {
          this.value = data?.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
// private/widgets/week-total
}
</script>

<style lang="scss" scoped>
.chart {
  padding: 20px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  &__start {
    font-size: 14px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  &__center {
    font-size: 36px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
    text-align: center;
  }

  &__end {
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #787C8D;
    font-family: Medium;

    span {
      font-size: inherit;
      color: #00B307;
      font-family: SemiBold;
    }
  }
}
</style>
