<template>
  <UiPage
    :title="$t('app.page.payouts')"
    :titleTemplate="$t('app.page.payouts')"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="grid">
        <TotalChart />

        <StatsChart
          v-if="$store.state.window.width >= 1700"
        />

        <BalancePayment />

        <StatsChart
          v-if="$store.state.window.width < 1700"
          class="span-columns"
        />
      </div>

      <Container
        class="list"
        :preloader="preloader"
      >
        <template #content>
          <div class="list__top">
            <FilterRangeDate
              class="filter-list"
              :value="date"
              @updateDate="date = $event"
            />
          </div>

          <table
            v-if="!$store.state.window.to768"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('views.default.payouts.period') }}
                </th>

                <th>
                  {{ $t('views.default.payouts.from-shopping') }}
                </th>

                <th>
                  {{ $t('views.default.payouts.from-line') }}
                </th>

                <th>
                  {{ $t('views.default.payouts.from-network') }}
                </th>

                <th>
                  {{ $t('views.default.payouts.total') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <template v-if="data.length > 0">
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td class="date">
                    {{ item.name }}
                  </td>

                  <td>
                    $ {{ $format.wallet(item.shopping, ',') }}
                  </td>

                  <td>
                    $ {{ $format.wallet(item.first_line, ',') }}
                  </td>

                  <td>
                    $ {{ $format.wallet(item.network, ',') }}
                  </td>

                  <td class="total">
                    $ {{ $format.wallet(item.total, ',') }}
                  </td>
                </tr>
              </template>

              <tr
                v-else
                class="none"
              >
                <td>
                  -
                </td>

                <td>
                  -
                </td>

                <td>
                  -
                </td>

                <td>
                  -
                </td>

                <td>
                  -
                </td>
              </tr>
            </tbody>
          </table>

          <div
            v-else
            class="list__content"
          >
            <template v-if="data.length > 0">
              <div
                v-for="item in data"
                :key="item.id"
                class="list__content__item"
              >
                <p class="small-text">
                  {{ item.name }}
                </p>

                <div class="list__content__item__row">
                  <p class="label">
                    {{ $t('views.default.payouts.from-shopping') }}
                  </p>

                  <p class="small-text">
                    $ {{ $format.wallet(item.shopping, ',') }}
                  </p>
                </div>

                <div class="list__content__item__row">
                  <p class="label">
                    {{ $t('views.default.payouts.from-line') }}
                  </p>

                  <p class="small-text">
                    $ {{ $format.wallet(item.first_line, ',') }}
                  </p>
                </div>

                <div class="list__content__item__row">
                  <p class="label">
                    {{ $t('views.default.payouts.from-network') }}
                  </p>

                  <p class="small-text">
                    $ {{ $format.wallet(item.network, ',') }}
                  </p>
                </div>

                <div class="list__content__item__row bottom">
                  <p class="label">
                    {{ $t('views.default.payouts.total') }}
                  </p>

                  <p class="small-text">
                    $ {{ $format.wallet(item.total, ',') }}
                  </p>
                </div>
              </div>
            </template>

            <div
              v-else
              class="list__content__item none"
            >
              ---
            </div>
          </div>

          <div
            v-if="data.length < count"
            class="list__controls"
          >
            <Pagination
              :name="$route.name"
              :pages="_countItemPagination"
              :load-more="data.length < count"
              :loading="isFetchMore"
              @loadMore="fetchMore()"
            />
          </div>
        </template>
      </Container>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'

import TotalChart from '@/modules/payouts/chart/Total.vue'
import StatsChart from '@/modules/payouts/chart/Stats.vue'
import BalancePayment from '@/modules/charts/BalancePayment.vue'

import FilterRangeDate from '@/ui/FilterRangeDate.vue'
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    UiPage,
    BackButton,
    Container,
    TotalChart,
    StatsChart,
    BalancePayment,
    FilterRangeDate,
    Pagination
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      data: [],
      date: null,
      count: 0,
      length: 10,
      isFetchMore: false
    }
  },
  computed: {
    _offset () {
      return ((this.$route.params.page ? this.$route.params.page : 1) * (this.length)) - (this.length)
    },
    _countItemPagination () {
      if (this.count) {
        return Math.ceil(this.count / (this.length))
      }

      return 0
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch(false)
    }
  },
  deactivated () {
    this.date = null

    if (this.data.length > this.length) {
      this.data = this.data.slice(0, this.length + 1)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        if (isCacheFetch) {
          this.preloader = true
        }

        const resp = await this.$axios.get(
          `/private/payouts?limit=${this.length}&offset=${this._offset}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`)

        if (resp.status === 200) {
          this.count = resp.data.count
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this._offset + (this.data.length ? this.data.length : 0)
      const link = `/private/payouts?offset=${offset}&limit=${this.length}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.data.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      if (this.$route.params.page) {
        this.fetch()
      }
    },
    date () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 370px auto 370px;
  gap: 20px;

  @media screen and (max-width: 1699px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }

  .span-columns {
    grid-column: span 2;
  }
}

.list {
  padding: 20px;
  border-radius: 15px;

  .list__top {
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #9CA3B8;

    @media screen and (max-width: 1280px) {
      margin-bottom: 15px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      tr {
        th {
          padding: 20px;
          text-align: left;
          font-size: 12px;
          line-height: 100%;
          color: rgb(var(--text-color-light-gray));
          font-family: Medium;
        }
      }
    }

    tbody {
      tr {
        &.none {
          font-size: 18px;
          line-height: 100%;
          color: #9CA3B8;
        }

        td {
          padding: 20px;
          color: rgb(var(--color-dark));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;

          &.date {
            color: rgb(var(--text-color-light-gray));
            font-family: Medium;
          }

          &.total {
            font-size: 16px;
            font-family: SemiBold;
          }
        }

        &:nth-child(odd) {
          td {
            background: rgba(240, 242, 248, .5);

            &:first-child {
              border-radius: 10px 0 0 10px;
            }

            &:last-child {
              border-radius: 0 10px 10px 0;
            }
          }
        }
      }
    }
  }

  .list__content {
    width: 100%;

    .list__content__item {
      width: 100%;
      padding: 15px 10px;
      border-radius: 10px;

      &:nth-child(odd) {
        background: rgba(240, 242, 248, .5);
      }

      p.small-text {
        margin-bottom: 15px;
        line-height: 100%;
        color: #787C8D;
        font-family: Medium;
      }

      .list__content__item__row {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &.none {
          font-size: 18px;
          line-height: 100%;
          color: #9CA3B8;
        }

        &.bottom {
          margin-top: 15px;

          p.small-text {
            line-height: 100%;
            font-size: 16px;
            font-family: SemiBold;
          }
        }

        p {
          margin: 0;
          line-height: 100%;
          font-family: Medium;

          &.label {
            font-size: 12px;
            color: #787C8D;
          }

          &.small-text {
            color: #202539;
          }
        }
      }
    }
  }

  .list__controls {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #9CA3B8;
  }
}
</style>
