<template>
  <Container
    class="stats"
    :preloader="preloader"
  >
    <template #content>
      <div class="stats__item">
        <div class="stats__item-name">
          {{ $t('views.default.payouts.charts.stats.income-all-purchases') }}
        </div>

        <div class="stats__item-value">
          ${{ $format.wallet(value.purchases_income) }}
        </div>

        <div class="stats__item-info">
          {{ $t('views.default.payouts.charts.stats.personal-purchase') }}

          <span>
            ${{ $format.wallet(value.turnover_user) }}
          </span>
        </div>
      </div>

      <div class="stats__item">
        <div class="stats__item-name">
          {{ $t('views.default.payouts.charts.stats.referral-payments') }}
        </div>

        <div class="stats__item-value">
          ${{ $format.wallet(value.referral_total_income) }}
        </div>

        <div class="stats__item-info">
          {{ $t('views.default.payouts.charts.stats.for-wholesale-purchases') }}

          <span>
            ${{ $format.wallet(value.referral_income) }}
          </span>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'

export default {
  components: {
    Container
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      value: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get(
          'private/widgets/payouts-stat')

        if (status === 200) {
          this.value = data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  position: relative;
  padding: 10px 20px;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .stats__item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    &:first-child {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid rgba(156, 163, 184, .6);

      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
        padding-right: 0;
        padding-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid rgba(156, 163, 184, .6);
      }
    }

    &-name {
      font-size: 14px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    &-value {
      font-size: 30px;
      line-height: 100%;
      text-align: center;
      color: #202539;
      font-family: SemiBold;
    }

    &-info {
      font-size: 12px;
      line-height: 100%;
      color: #787C8D;
      font-family: Medium;
      text-align: center;

      span {
        font-size: 14px;
        line-height: 100%;
        color: #00B307;
        font-family: SemiBold;
      }
    }
  }
}
</style>
